body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
    background-color: #fff;
}

.App {
    /* background-color: red; */
    width: 380px;
    height: 230px;
}

header {
    background-color: #fff;
    height: 50px;
}

header h1 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    line-height: 50px;
}

header h1 span {
    color: #1996ff;
}

main {
    position: relative;
    height: 180px;
}

main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3) url(./background.png) no-repeat center;
    background-size: cover;
    z-index: -2;
}

main::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15,15,25,0.9);
    z-index: -1;
}

button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border: none;
    outline: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    background: #1996ff;
    color: #fff;
    box-shadow: 0 0 10px rgba(255, 255, 255, .3);
}

button:hover {
    opacity: .8;
}